import React from "react";
import SiteContent from "./SiteContent";

const WrapperLayout = () => (
  <main className="site-wrapper">
    <SiteContent />
  </main>
);

export default WrapperLayout;
