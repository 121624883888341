import React, { Suspense } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import SiteAuthorisation from "./SiteAuthorisation";
import RouteList from "../routes/RouteList";
import Header from "./Header/Header";
import SiteSidebar from "./SiteSidebar";

const SiteContent = () => {
  return (
    <>
      <Header />
      <div className="main-section">
        <SiteSidebar />
        <section className="section-layout" id="section-layout">
          <Suspense fallback={null}>
            <Switch>
              {RouteList &&
                RouteList.map(
                  (route) =>
                    route.component && (
                      <Route
                        key={route.name}
                        path={route.path}
                        exact={route.exact}
                        name={route.name}
                        render={(props) => (
                          <SiteAuthorisation
                            name={route.name}
                            type={route.type}
                            key={route.name}
                          >
                            <route.component {...props} />
                          </SiteAuthorisation>
                        )}
                      />
                    )
                )}
              <Redirect from="/" to="/users-list" />
            </Switch>
          </Suspense>
        </section>
      </div>
    </>
  );
};

export default SiteContent;
