import React from "react";
import { NavLink } from "react-router-dom";

const SiteSidebar = () => {
  return (
    <div className="leftnav">
      <div className="leftnav-inner">
        <ul className="list-unstyled  mb-0">
          <li>
            <NavLink to="/dashboard">Dashboard</NavLink>
          </li>
          <li>
            <NavLink to="/users-list">User List</NavLink>
          </li>
          <li>
            <NavLink to="/charging-stations">Charging Station</NavLink>
          </li>
          <li>
            <NavLink to="/stations-request">Station Requested</NavLink>
          </li>
          <li>
            <NavLink to="/stations-reported">Station Reported</NavLink>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default SiteSidebar;
