/* eslint-disable no-undef */

import { useEffect } from "react";
import history from "../helpers/History";
import { fetchToken } from "../helpers/storageData";

const SiteAuthorisation = (props) => {
  const token = fetchToken();
  useEffect(() => {
    // checkUser();
    if (!token) {
      history.push("/login");
    }
  }, []);

  return { ...props.children };
};

export default SiteAuthorisation;
