import React, { Suspense } from "react";
import { Router } from "react-router-dom";

import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import history from "./helpers/History";
import Routes from "./routes/Routes";
const loader = "";

const App = () => (
  <Router history={history}>
    <Suspense fallback={loader}>
      <Routes />
    </Suspense>
  </Router>
);

export default App;
