import React from "react";
import { Route, Switch } from "react-router-dom";
import { Slide, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import WrapperLayout from "../layouts/WrapperLayout";

const Login = React.lazy(() => import("../pages/Login/Login"));

const Routes = () => (
  <>
    <ToastContainer
      position="top-right"
      autoClose={3000}
      transition={Slide}
      hideProgressBar={false}
      newestOnTop={false}
      closeOnClick
      rtl={false}
      pauseOnFocusLoss={false}
      draggable
      pauseOnHover={false}
    />
    <Switch>
      <Route exact path="/login" render={(props) => <Login {...props} />} />
      <Route path="/" render={(props) => <WrapperLayout {...props} />} />
    </Switch>
  </>
);

export default Routes;
